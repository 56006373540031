import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

const WeRebuild: React.FC = () => {
  const [text, setText] = useState("");
  const [showCursor, setShowCursor] = useState(true);
  const [showCircle, setShowCircle] = useState(false);
  const fullText = "We Rebuild Education";

  useEffect(() => {
    if (text.length < fullText.length) {
      const timeout = setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, 150);
      return () => clearTimeout(timeout);
    } else {
      setShowCircle(true);
      setShowCursor(false);
    }
  }, [text]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 530);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ position: "relative", mb: 4 }}>
      <Box
        component="h1"
        sx={{
          fontWeight: 700,
          color: "#fff",
          display: "inline-flex",
          alignItems: "center",
          position: "relative",
          fontSize: "3rem",
          margin: 0,
        }}
      >
        {text}
        {showCircle && (
          <Box
            component="span"
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              backgroundColor: "#ff6b00",
              display: "inline-block",
              position: "absolute",
              bottom: "10px",
              right: "-14px",
            }}
          />
        )}
        {!showCircle && (
          <Box
            component="span"
            sx={{
              borderRight: "4px solid #fff",
              marginLeft: "1px",
              animation: showCursor ? "none" : "none",
              opacity: showCursor ? 1 : 0,
              transition: "opacity 0.1s",
            }}
          >
            &nbsp;
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WeRebuild;
