/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Typography, Box } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import { GoogleLoginButton } from "react-social-login-buttons";

export interface SignInParameters {
  title: string;
  showGoogleLogin: boolean;
  onSignInWithGoogle: () => boolean;
  footer?: string | undefined;
}

export class SignIn extends React.Component<
  SignInParameters,
  { email: string }
> {
  constructor(props: SignInParameters) {
    super(props);
    this.state = {
      email: "",
    };
  }

  handleChange = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  render(): JSX.Element {
    const buttonStyle = {
      fontSize: "medium",
      marginTop: 6,
      marginBottom: 6,
    };
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600, textAlign: "center" }}>
          {this.props.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 4, color: "grey.500", textAlign: "center" }}
        >
          Sign in with mathpresso.com
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {this.props.showGoogleLogin && (
            <GoogleLoginButton
              style={{
                ...buttonStyle,
                height: "48px",
                fontSize: "16px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                color: "#000",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                transition: "background-color 0.2s",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
              onClick={this.props.onSignInWithGoogle}
            />
          )}
        </Box>
        <Box sx={{ textAlign: "center", color: "grey.400", fontSize: "12px" }}>
          {this.props.footer && (
            <ReactMarkdown>{this.props.footer}</ReactMarkdown>
          )}
        </Box>
      </Box>
    );
  }
}
