/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except
 * in compliance with the License. You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under the
 * License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from "react";
import {
  Container,
  Box,
  Paper,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Typography,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { SignIn, SignInParameters } from "./signin";
import { SignInWithEmail, SignInWithEmailParameters } from "./signinwithemail";
import { SignUpWithEmail, SignUpWithEmailParameters } from "./signupwithemail";
import SignOut from "./signout";
import ProgressBar from "./progressbar";
import { Alert, AlertParameters } from "./alert";
import { LoginUiConfigType } from "../common/type";
import { getLoginUiConfig, processUser } from "../apis/api";
// Import Firebase dependencies.
import firebase from "firebase/app";
import "firebase/auth";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserCredential, FirebaseAuth, User } from "@firebase/auth-types";
// Import GCIP/IAP module.
import * as ciap from "gcip-iap";
import WeRebuild from "./werebuild";

const theme = createTheme({
  palette: {
    mode: window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

interface Props {
  location?: any;
}

interface AppState {
  mode?: "SIGN_IN" | "SIGN_OUT" | "PROGRESS_BAR" | "NONE";
  alertParams?: AlertParameters;
  signIn?: SignInParameters;
}

const processUserInternal = async (
  authApiKey: string | null,
  uid: string,
  myStorage: Storage,
  config: LoginUiConfigType
) => {
  console.log("processUserInternal called");
  const projectName = config.projectName;
  const token = await processUser(authApiKey, projectName, uid);
  const userToken = token.token;
  myStorage.setItem(`${projectName}.userToken`, userToken);
};
class App
  extends React.Component<Props, AppState>
  implements ciap.AuthenticationHandler
{
  private progressBarTimer: any;
  private ciapInstance: any;
  private config: any;
  private myStorage = window.localStorage;

  constructor(props: Props) {
    super(props);
    this.progressBarTimer = null;
    this.state = {};
  }

  componentDidMount() {
    const apiKey = this.getApiKeyFromQueryParam();
    console.log(`API key = ${apiKey}`);
    getLoginUiConfig(apiKey)
      .then((resp: any) => resp.json())
      .catch((error: any) => {
        throw new Error("Failed to fetch config.");
      })
      .then((config: any) => {
        this.config = config;
        this.ciapInstance = new ciap.Authentication(this);
        if (this.ciapInstance === undefined) {
          throw new Error(
            `Failed to initialize auth instance. config : ${this.config}`
          );
        }
        const p = this.ciapInstance.start();
        return p;
      })
      .catch((error: any) => {
        this.updateError(error);
      });
  }

  render(): JSX.Element {
    const alertParams = this.state.alertParams || {};
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            backgroundColor: "#000",
            color: "#fff",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              height: "100vh",
              backgroundColor: "rgba(24, 27, 27, 0.7)",
              display: {
                xl: "flex",
                lg: "flex",
                md: "none",
                sm: "none",
                xs: "none",
              },
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              border: "0.1px solid rgba(255, 255, 255, 0.2)",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 20,
                left: 20,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                component="img"
                src="/logo.png"
                alt="QANDA Logo"
                sx={{
                  height: "20px",
                  width: "auto",
                }}
              />
            </Box>
            <WeRebuild />
          </Box>

          <Box
            sx={{
              p: 14,
              ...(theme.palette.mode === "light" && {
                backgroundColor: "#fff",
                color: "rgba(24, 12, 12, 0.87)",
              }),
              minWidth: {
                xl: "33.4%",
                lg: "33.4%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderTop: "0.1px solid rgba(255, 255, 255, 0.2)",
              borderBottom: "0.1px solid rgba(255, 255, 255, 0.2)",
              borderRight: "0.1px solid rgba(255, 255, 255, 0.2)",
            }}
          >
            <Box sx={{ position: "absolute", top: 5, right: 5 }}>
              <IconButton
                onClick={() => {
                  const newMode =
                    theme.palette.mode === "light" ? "dark" : "light";
                  theme.palette.mode = newMode;
                  this.forceUpdate();
                }}
                sx={{
                  p: 1,
                  color: theme.palette.mode === "light" ? "#000" : "#fff",
                }}
              >
                {theme.palette.mode === "light" ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <circle cx="12" cy="12" r="5" />
                    <line x1="12" y1="1" x2="12" y2="3" />
                    <line x1="12" y1="21" x2="12" y2="23" />
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                    <line x1="1" y1="12" x2="3" y2="12" />
                    <line x1="21" y1="12" x2="23" y2="12" />
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                  </svg>
                )}
              </IconButton>
            </Box>

            {this.renderCiapComponent()}

            <Alert
              sx={{
                maxWidth: "480px",
                textAlign: "center",
              }}
              code={alertParams.code}
              message={alertParams.message}
              retry={alertParams.retry}
            />
          </Box>
        </Box>
      </ThemeProvider>
    );
  }

  private getApiKeyFromQueryParam(): string | null {
    const search = this.props.location.search;
    return new URLSearchParams(search).get("apiKey");
  }

  public getAuth(apiKey: string, tenantId: string | null): FirebaseAuth {
    let auth = null;
    console.log(`getAuth called. apiKey=${apiKey}, tenantId=${tenantId}`);
    const app = firebase.initializeApp(this.config);
    auth = app.auth();
    if (this.config.tenantId) {
      auth.tenantId = this.config.tenantId;
    }
    return auth as any;
  }

  public handleError(error: any) {
    this.updateError(error);
  }

  public selectTenant(
    projectConfig: { projectId: string },
    tenantIds: string[]
  ): Promise<ciap.SelectedTenantInfo> {
    console.log("selectTenant called");
    throw new Error("selectTenant not available");
  }

  public processUser(user: User): Promise<User> {
    console.log("processUser called");
    const authApiKey = this.getApiKeyFromQueryParam();
    const uid = user.uid;
    console.log("before processUserInternal");
    return new Promise((resolve, rejects) => {
      processUserInternal(authApiKey, uid, this.myStorage, this.config)
        .then(() => {
          return user.getIdToken(true);
        })
        .then(() => {
          resolve(user);
        });
    });
  }

  public startSignIn(auth: FirebaseAuth): Promise<UserCredential> {
    console.log("startSignIn called");
    return new Promise((resolve, reject) => {
      this.signIn(
        /* title */
        this.config.loginUi.title,
        /* showGoogleLogin */
        this.config.loginUi.showGoogleLogin,
        /* onSignInWithGoogle */
        () => {
          this.updateError(null);
          auth
            .signInWithPopup(new firebase.auth.GoogleAuthProvider())
            .then((cred: any) => resolve(cred))
            .catch((error: any) => {
              this.updateError(error);
            });
          return false;
        },
        this.config.loginUi.footer
      );
    });
  }

  public completeSignOut(): Promise<void> {
    this.signOut();
    return Promise.resolve();
  }

  public hideProgressBar() {
    clearTimeout(this.progressBarTimer);
    this.hideContainer();
  }

  public showProgressBar() {
    // Show progress bar only if it takes longer than a certain delay.
    // This prevents flicker effects when a transition is quick and a spinner
    // is shown in between.
    this.progressBarTimer = setTimeout(() => {
      this.renderProgressBar();
    }, 1000);
  }

  private updateError(
    error: { code?: string; message?: string; retry?: any } | null
  ) {
    const modifiedState: AppState = {
      alertParams: {
        code: (error && error.code) || undefined,
        message: (error && error.message) || undefined,
        retry: (error && error.retry) || undefined,
      },
      // Keep existing values for the rest of the state.
      mode: this.state.mode,
      signIn: this.state.signIn,
    };
    this.setState(modifiedState);
  }

  private signIn(
    title: string,
    showGoogleLogin: boolean,
    onSignInWithGoogle: () => boolean,
    footer: string
  ) {
    this.setState({
      mode: "SIGN_IN",
      signIn: {
        title,
        showGoogleLogin,
        onSignInWithGoogle,
        footer,
      },
    });
  }

  private signOut() {
    this.setState({
      mode: "SIGN_OUT",
    });
  }

  private renderProgressBar() {
    this.setState({
      mode: "PROGRESS_BAR",
    });
  }

  private hideContainer() {
    this.setState({
      mode: "NONE",
    });
  }

  private renderCiapComponent = (): JSX.Element => {
    switch (this.state.mode) {
      case "SIGN_IN":
        const signIn = this.state.signIn as SignInParameters;
        return (
          <SignIn
            title={signIn.title}
            showGoogleLogin={signIn.showGoogleLogin}
            onSignInWithGoogle={signIn.onSignInWithGoogle}
            footer={signIn.footer}
          />
        );
      case "SIGN_OUT":
        return <SignOut />;
      case "PROGRESS_BAR":
        return <ProgressBar />;
      default:
        return <div></div>;
    }
  };
}

export default App;
